// eslint-disable-next-line no-redeclare,no-unused-vars
function build() {
  $('<div id="tooltip" class="d-none"></div>').prependTo(document.body);
  var header = buildHeader();
  var main = $('<main class="d-flex"></main>').insertAfter(header);

  // build main areas
  sidebar = $(
    '<nav id="sidebar" class="sidebar d-flex flex-column flex-shrink-0 text-light bg-secondary"></nav>'
  ).appendTo(main);
  buildStoreInfo(sidebar);
  buildMenu(sidebar);
  buildDashboard(main);

  views = $('<div id="views" class="flex-fill d-none"></div>').appendTo(main);
  var contViews = $('<div class="container-fluid"></div>').appendTo(views);
  views.row = $('<div id="views-row" class="row"></div>').appendTo(contViews);

  // fill areas
  ajax({
    object: 'start',
    callback: function (xhr, sortChange) {
      if (xhr.boxData) fillDashboard(xhr, sortChange);
      // if (data.guide) guide();
    },
  });

  // user active
  var timer;
  $(document.body).on('mousemove', function () {
    active = true;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      active = false;
    }, 3000);
    if (pdfPreview) {
      pdfPreview.remove();
      pdfPreview = null;
    }
  });

  // go
  $(document).scrollTop(0);
  serverEvents();
  if (!test)
    window.onbeforeunload = function () {
      if (!window.logoff && noLogoutInfo) noLogoutInfo = false;
      else return info.prompt.closePage;
    };
  background();
  uploader();
  buildHistory();

  // helpers
  var helperCont = $('<div id="helpers" class="invisible"></div>').appendTo(
    document.body
  );
  $('<div id="widthHelper" class="helper"></div>').appendTo(helperCont);
  $('<textarea id="heightHelper" class="helper"></textarea>').appendTo(
    helperCont
  );
  $(
    '<iframe id="iframe" name="iframe" class="d-none" src=""></iframe>'
  ).appendTo(helperCont);

  // direct call
  var path = document.location.pathname.slice(1);
  if (path) {
    var pm = {directCall: true};
    if (str_contains(path, ',')) [pm.object, pm.id] = path.split(',');
    else pm.object = path;
    if (str_contains(pm.object, '+'))
      [pm.object, pm.objectSub] = pm.object.split('+');
    if (pm.id) pm.func = 'detail';
    else pm.func = 'overview';
    // if (document.location.search)
    ajax(pm);
    if (!develop) window.history.pushState('', '', '/');
  }
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function background() {
  $('main > style').remove();
  if (user.background)
    $('main').append(
      '<style>main:before{background-image:url(' +
        getObjectValue(data, ['document', user.background, 'file']) +
        ');}</style>'
    );
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function uploader() {
  $(document.body)
    .on('dragover', function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      var target = $(evt.target);
      if (target.hasClass('upload')) dropzone = target;
      else if (target.parents('.upload')[0])
        dropzone = target.parents('.upload');
      // else dropzone = $('.upload:visible:first');
      if (dropzone)
        dropzone.find('.btn').removeClass('btn-light').addClass('btn-primary');
    })
    .on('dragleave', function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (dropzone)
        dropzone.find('.btn').addClass('btn-light').removeClass('btn-primary');
      dropzone = null;
    })
    .on('drop', function (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (dropzone) {
        dropzone.children('input')[0].files =
          evt.originalEvent.dataTransfer.files;
        dropzone.children('input').trigger('change');
        dropzone.find('.btn').addClass('btn-light').removeClass('btn-primary');
        dropzone = null;
      }
    });
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function serverEvents() {
  var loading = false;
  var stopped = false;

  var call = function () {
    var pm = {headers: {}, xhrFields: {withCredentials: true}};
    pm.url = endpoint + 'event?window=' + windowId;
    pm.dataType = 'json';
    var request = $.ajax(pm);
    request.always(function (xhr) {
      loading = false;
      ajaxResponse({event: true}, xhr);
      if (xhr.logout && !user.admin) {
        stopped = true;
        var pm = {
          title: 'Autologout',
          descr: info.autologoutInfo.replace('__time__', xhr.time),
          buttons: {},
          noclose: 'all',
          noCloseBtn: true,
        };
        pm.buttons['weiter machen'] = function () {
          popup.save();
        };
        pm.buttons['neu einloggen'] = function () {
          reload();
        };
        var popup = buildPromptPopup(
          {
            password: {label: 'Passwort erneut eingeben', field: 'password'},
          },
          function (password) {
            if (password)
              ajax({
                object: 'login',
                view: popup,
                send: {user: user.username, password: password},
                post: true,
                callback: function (xhr) {
                  if (!xhr.error) {
                    popup.close();
                    stopped = false;
                  }
                },
              });
          },
          pm
        );
      }
    });
  };

  setInterval(function () {
    if (loading || stopped) return;
    loading = true;
    if (active) requestIdleCallback(call);
    else call();
  }, 5000);
}
